import React from 'react';

import NavbarCustom from './../../components/NavbarCustom'
import Footer from './../../components/Footer'
import Page from './Page'

function index() {
    return (
        <>
            <NavbarCustom/>
            <Page />
            <Footer />
        </>
    );
}
  
export default index