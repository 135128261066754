import React from "react";

import "./style.scss";
import bg1 from "./../../assets/image/1.png";
import bg5 from "./../../assets/image/19.png";
import bg6 from "./../../assets/image/20.png";
import bg7 from "./../../assets/image/21.png";
import bg8 from "./../../assets/image/22.png";

import Fold1 from "../../components/Fold1";
import Fold2 from "../../components/Fold2";
import Fold3 from "../../components/Fold3";
import Fold4 from "../../components/Fold4";
import Fold5 from "../../components/Fold5";
import Fold6 from "../../components/Fold6";
import Fold7 from "../../components/Fold7";

function Page() {
  return (
    <div className="page-resources">
      <img src={bg1} className="bg1" alt="bg" />
      <img src={bg5} className="bg5" alt="bg" />
      <img src={bg6} className="bg6" alt="bg" />
      <img src={bg7} className="bg7" alt="bg" />
      <img src={bg8} className="bg8" alt="bg" />

      <div className="container">
        <Fold1 />
        <Fold2 />
        <Fold3 />
        <Fold4 />
        <Fold5 />
        <Fold6 />
        <Fold7 />
      </div>
    </div>
  );
}

export default Page;
