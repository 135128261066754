import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Main from './pages/Main'
import Partners from './pages/Partners'

function routes() {
    return (
        <Switch>
            <Route path="/" component={Main} exact />
            <Route path="/partners" component={Partners} exact />
        </Switch>
    )
}

export default routes