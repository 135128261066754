import React from "react";
import { useHistory } from "react-router-dom";

import img8 from "./../assets/image/18.png";

export default function Fold7() {
  const history = useHistory();
  return (
    <>
      <div id="become"></div>

      <div className="row banner" style={{ margin: "0", padding: "80px 10px" }}>
        <div className="col-12 d-flex justify-content-center">
          <button
            className="btn-custom-theme mb-5 mt-3"
            onClick={() => {
              history.push("/partners");
            }}
          >
            I WANT TO BE A PARTNER{" "}
            <img src={img8} className="ml-2 mb-1" alt="" />
          </button>
        </div>
      </div>
    </>
  );
}
