import React, { useEffect, useState } from 'react'
import axios from 'axios'
import swal from 'sweetalert'

import Footer from './../../components/Footer'
import './style.scss'

import bg from './../../assets/image/17085.png'
import bg2 from './../../assets/image/20.png'
import bg3 from './../../assets/image/19.png'
import img01 from './../../assets/image/logo.png'
import img02 from './../../assets/image/17081.png'

import img03 from './../../assets/image/17082.png'
import img04 from './../../assets/image/17083.png'
import img05 from './../../assets/image/17084.png'

function Index() {

    useEffect(() => window.scrollTo(0, 0), [])
    
    const [name,setName] = useState('')
    const [email,setEmail] = useState('')
    const [phone,setPhone] = useState('')
    const [service,setService] = useState('')
    const [markets,setMarkets] = useState('')

    const submit = (e) => {
        e.preventDefault()
        var data = {
            name,
            email,
            phone,
            service,
            markets
        }
        axios.post('https://formspree.io/meqrwova', data).then(res => {
            swal("Good job!", "we will contact you soon!", "success");
            setName('')
            setEmail('')
            setPhone('')
            setService('')
            setMarkets('')
        })
    }

    return (
        <>
            <div className="partners" >
                <img src={bg} alt="bg" className="bg" />
                
                <div className=" container"  >
                    <div className="row">
                        <div className="col-12 col-lg-6 mb-5">
                            <div className="d-flex justify-content-center justify-content-lg-start">
                                <img src={img01} alt="img" className="logo mt-5 mb-4" />
                            </div>   
                            <div className="title text-center text-lg-left">
                                <div>Become a <strong className="font-color-orange">Stratus</strong></div>
                                <div className="font-400">Partner and offer</div>
                                <div className="font-400">CX services</div>
                                <div className="font-400">to your</div>
                                <div>best customers</div>
                            </div>
                            <div className="d-flex justify-content-center justify-content-lg-start">
                                <img src={img02} alt="img" className="img02" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 mb-5">
                            <div className="box-form mb-5 mb-lg-0">
                                <form onSubmit={submit} className="d-flex flex-column justify-content-center align-items-center p-5">
                                    <div className="form-group w-100">
                                        <label><strong>Name: *</strong></label>
                                        <input name="name" className="form-control" value={name} onChange={e => setName(e.target.value)} required/>
                                    </div>
                                    <div className="form-group w-100">
                                        <label><strong>E-mail: *</strong></label>
                                        <input type="email" name="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} required/>
                                    </div>
                                    <div className="form-group w-100">
                                        <label><strong>Phone: *</strong></label>
                                        <input name="telefone" className="form-control" value={phone} onChange={e => setPhone(e.target.value)} required/>
                                    </div>
                                    <div className="form-group w-100">
                                        <label><strong>What kind of service do you offer? *</strong></label>
                                        <select name="servico" className="form-control" value={service} onChange={e => setService(e.target.value)} required>
                                            <option value="">select an option...</option>
                                            <option>Consulting</option>
                                            <option>Software as a Service</option>
                                            <option>App</option>
                                        </select>
                                    </div>
                                    <div className="form-group w-100 mb-5">
                                        <label><strong>Which markets do you operate? *</strong></label>
                                        <select name="mercado" className="form-control" value={markets} onChange={e => setMarkets(e.target.value)} required>
                                            <option value="">select an option...</option>
                                            <option>Retail</option>
                                            <option>Education</option>
                                            <option>Financial Market</option>
                                            <option>Health</option>
                                            <option>Utilities</option>
                                            <option>Government</option>
                                            <option>Employee</option>
                                            <option>Logistics and Distribution</option>
                                            <option>Virtual Sales</option>
                                        </select>
                                    </div>
                                    <button type="submit" className="btn-custom-theme">
                                        REGISTER
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ position: 'relative', paddingBottom: '100px' }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="title text-center mb-5">
                                    Count on Stratus CX to:
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="card-partners mb-5">
                                    <div className="d-flex justify-content-center align-items-center w-100 h-50">
                                        <img src={img03} alt="img" className="h-100" />
                                    </div>
                                    <div className="subtitle d-flex flex-column justify-content-center align-items-center w-100 h-50">
                                        <div>Find the ideal</div> 
                                        <div>customers</div> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="card-partners orange mb-5">
                                    <div className="d-flex justify-content-center align-items-center w-100 h-50">
                                        <img src={img04} alt="img" className="h-100 mr-3" />
                                    </div>
                                    <div className="subtitle d-flex flex-column justify-content-center align-items-center w-100 h-50">
                                        <div>Reduce the</div>
                                        <div>purchase journey</div>
                                        <div>process</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="card-partners mb-5">
                                    <div className="d-flex justify-content-center align-items-center w-100 h-50">
                                        <img src={img05} alt="img" className="h-100" />
                                    </div>
                                    <div className="subtitle d-flex flex-column justify-content-center align-items-center w-100 h-50">
                                        <div>Receive contact</div> 
                                        <div>from potential</div>
                                        <div>customers</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <img src={bg2} alt="bg" className="bg2" />
                    <img src={bg3} alt="bg" className="bg3" />

                </div>
                
            </div>        
            <Footer />
        </>
    )
}

export default Index