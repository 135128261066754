import React from "react";

import bg4 from "./../assets/image/4.png";

export default function Fold2() {
  return (
    <>
      <div id="about"></div>

      <div className="row banner">
        <div className="col-12 col-lg-6 p-0 d-flex justify-content-center justify-content-lg-start align-items-center mt-5 mb-5 order-2 order-lg-1">
          <img src={bg4} className="bg4" alt="bg" />
        </div>
        <div className="col-12 col-lg-6 p-0 d-flex justify-content-center align-items-center order-1 order-lg-2">
          <div className="text-center text-lg-left">
            <div className="title">The CX cloud</div>
            <div className="description">
              Stratus CX is a marketplace that brings together the best
              solutions of Customer Experience Universe. It is a space to
              facilitate the exchanging of experiences between companies and
              clients that are looking for the ideal solutions to transform
              relationship actions and strategies with clients.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
