import React from "react";
import logo from "./../../assets/image/logo2.png";

import email from "./../../assets/image/12.png";
import phone from "./../../assets/image/13.png";
import point from "./../../assets/image/14.png";

import insta from "./../../assets/image/15.png";
import face from "./../../assets/image/16.png";
import link from "./../../assets/image/17.png";

import img8 from "./../../assets/image/18.png";

import "./style.scss";

function Footer() {
  return (
    <div className="footer p-3 p-lg-0">
      <div className="container">
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-12 col-lg-3 d-flex justify-content-center align-items-center p-3">
            <img src={logo} className="footer-logo" alt="logo" />
          </div>
          <div className="col-12 col-md-6 col-lg-3 d-flex flex-column justify-content-center align-items-center p-3">
            <div>
              <div className="footer-title">
                <img src={img8} className="mr-2 mb-1" alt="" />
                Contact Us
              </div>
              <div>
                <div className="footer-description d-flex justify-content-center justify-content-lg-start">
                  <img
                    src={email}
                    style={{
                      width: "15px",
                      height: "12px",
                      margin: "4px 5px 0 0",
                    }}
                    alt="email"
                  />
                  <div>
                    <div>contact@stratus.cx</div>
                  </div>
                </div>
                <div className="footer-description d-flex justify-content-center justify-content-lg-start">
                  <img
                    src={phone}
                    style={{
                      width: "15px",
                      height: "15px",
                      margin: "3px 5px 0 0",
                    }}
                    alt="phone"
                  />
                  <div>
                    <div>+55 0800 724 8181</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 d-flex flex-column justify-content-center align-items-center p-3">
            <div>
              <div className="footer-title">
                <img src={img8} className="mr-2 mb-1" alt="" />
                Find Us
              </div>
              <div className="footer-description d-flex justify-content-center justify-content-lg-start">
                <img
                  src={point}
                  style={{
                    width: "12px",
                    height: "15px",
                    margin: "3px 5px 0 0",
                  }}
                  alt="point"
                />
                <div>
                  <div>João Pessoa</div>
                  <div>São Paulo</div>
                  <div>Miami</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 d-flex justify-content-center align-items-center p-3">
            <a href="https://www.instagram.com/stratus.cx/">
              <img src={insta} className="footer-social" alt="instagram" />
            </a>
            <a href="https://www.facebook.com/stratus.cx">
              <img src={face} className="footer-social" alt="facebook" />
            </a>
            <a href="https://twitter.com/stratuscx">
              <img src={link} className="footer-social" alt="linkedin" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
