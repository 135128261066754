import React, { useState, useEffect } from "react";

import logo from "./../../assets/image/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

import "./style.scss";

function width() {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.getElementsByTagName("body")[0].clientWidth
  );
}

function NavbarCustom() {
  const [fixed, setFixed] = useState(false);
  const [active, setActive] = useState(false);
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (width() < 992) {
      setFixed(true);
    } else {
      if (position > 50) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll, { passive: true });
    window.addEventListener("resize", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  return (
    <div className={`navbar-custom ${fixed ? "fixed" : ""}`}>
      <div className="container">
        <div>
          <img className="logo" src={logo} alt="logo" />
        </div>
        <ul className={`menu-items ${active ? "active" : ""}`}>
          <li className="item-close">
            <div className="btn-menu" onClick={() => setActive(false)}>
              <FontAwesomeIcon icon={faTimes} size="lg" color="#868b8d" />
            </div>
          </li>
          <li className="item">
            <a className="item-link" href="#about">
              About us
            </a>
          </li>
          <li className="item">
            <a className="item-link" href="#experiences">
              What experiences?
            </a>
          </li>
          <li className="item">
            <a className="item-link" href="#partners">
              Partners
            </a>
          </li>
          <li className="item">
            <a
              className="item-link active"
              href="#our-products"
              style={{ background: "#43365c", color: "#ffffff" }}
            >
              Use Our Products
            </a>
          </li>
        </ul>
        <div className="btn-menu" onClick={() => setActive(true)}>
          <FontAwesomeIcon icon={faBars} size="lg" color="#868b8d" />
        </div>
      </div>
    </div>
  );
}

export default NavbarCustom;
