import React from "react";

import bg2 from "./../assets/image/2.png";
import img8 from "./../assets/image/18.png";

export default function Fold1() {
  return (
    <div className="row banner">
      <div className="col-12 col-lg-6 p-0 d-flex justify-content-center align-items-center">
        <div className="text-center text-lg-left">
          <div className="title">
            The best CX solutions in cloud to increase the customer experience
            levels
          </div>
          <div className="description">
            Get to know new companies and how they can leverage strategies and
            help to offer memorable experiences to your clients
          </div>
          <button
            className="btn-custom-theme mb-5 mt-5"
            href="#our-products"
            onClick={() => {
              document.getElementById("our-products-link").click();
            }}
          >
            <a href="#our-products" id="our-products-link" className="d-none">
              a
            </a>
            Know Our Products
            <img src={img8} className="ml-2 mb-1" alt="" />
          </button>
        </div>
      </div>
      <div className="col-12 col-lg-6 p-0 d-flex justify-content-center justify-content-lg-end align-items-center mt-5 mb-5">
        <img src={bg2} className="bg2" alt="bg" />
      </div>
    </div>
  );
}
