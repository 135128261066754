import React from "react";

import iconeCx from "./../assets/image/icone-cx.png";
import iconePatient from "./../assets/image/icone-patient.png";
import iconeCitizen from "./../assets/image/icone-pessoas-laranja.png";
import iconeOthers from "./../assets/image/icone-others.png";

export default function Fold5() {
  return (
    <>
      <div id="experiences"></div>

      <div className="col-12 text-center">
        <div className="title">Experience for all</div>
      </div>

      <div className="row banner flex-column-reverse flex-lg-row mt-lg-5 mb-3 mb-lg-5">
        <div className="col-12 col-lg-6">
          <div className="card-custom card-c01">
            <div className="row w-100">
              <div className="col-4 d-flex justify-content-center align-items-center">
                <img src={iconeCx} className="img-banner3" alt="ipng" />
              </div>
              <div className="col-8 d-flex align-items-center">
                <div>
                  <div className="subtitle">Customer Experience</div>
                  <div className="description">
                    Retail, financial market, utilities, education, and the
                    entire corporate sector.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6">
          <div className="card-custom card-c01 orange">
            <div className="row w-100">
              <div className="col-4 d-flex justify-content-center align-items-center">
                <img src={iconePatient} className="img-banner3" alt="ipng" />
              </div>
              <div className="col-8 d-flex align-items-center">
                <div>
                  <div className="subtitle">Patient Experience</div>
                  <div className="description">
                    Hospitals, clinics, and doctors’ surgeries.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-xl-6 order-2 order-lg-1">
          <div className="card-custom card-c01">
            <div className="row w-100">
              <div className="col-4 d-flex justify-content-center align-items-center">
                <img src={iconeCitizen} className="img-banner3" alt="ipng" />
              </div>
              <div className="col-8 d-flex align-items-center">
                <div>
                  <div className="subtitle">Citizen experience</div>
                  <div className="description">
                    Health, public safety, transport, and education.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-xl-6 order-1 order-lg-2">
          <div className="card-custom card-c01">
            <div className="row w-100">
              <div className="col-4 d-flex justify-content-center align-items-center">
                <img src={iconeOthers} className="img-banner3" alt="ipng" />
              </div>
              <div className="col-8 d-flex align-items-center">
                <div>
                  <div className="subtitle">Other Experiences</div>
                  <div className="description">
                    Employee, logistics and virtual sales
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
