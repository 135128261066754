import React from "react";

export default function CardCustomFold3({ img, children }) {
  return (
    <div className="col-12 col-lg-6 col-xl-3">
      <div className="card-custom flex-column card-fold3">
        <div
          className="h-25 d-flex justify-content-center align-items-center mb-3"
          style={{ minHeight: "100px" }}
        >
          <img src={img} className="img-banner4" alt="logo" />
        </div>
        <div className="h-75">{children}</div>
      </div>
    </div>
  );
}
