import React from "react";
import img8 from "./../assets/image/18.png";
import jornada from "./../assets/image/jornada-site-x2.png";

export default function Fold4() {
  return (
    <>
      <div className="row banner mt-3">
        <div className="col-12 col-lg-6 p-0 d-flex justify-content-center align-items-center">
          <div className="text-center text-lg-left">
            <div className="title">
              Map your <span className="orange">customer's journey</span> to get
              the best performance from each solution and increase satisfaction
              levels
            </div>
            <div className="description">
              Surprise yourself with a simple and effective method!
            </div>
            <a
              href="https://materiais.citrus.cx/contact-mapping-stratus-cx"
              id="contact-mapping-stratus-cx"
              className="d-none"
              target="_blank"
              rel="noopener noreferrer"
            >
              a
            </a>
            <button
              className="btn-custom-theme mb-5 mt-5"
              onClick={() => {
                document.getElementById("contact-mapping-stratus-cx").click();
              }}
            >
              START NOW
              <img src={img8} className="ml-2 mb-1" alt="" />
            </button>
          </div>
        </div>
        <div className="col-12 col-lg-6 p-0 d-flex justify-content-center justify-content-lg-end align-items-center mt-5 mb-5">
          <img src={jornada} className="bg2" alt="bg" />
        </div>
      </div>
    </>
  );
}
