import React from "react";

import img4 from "./../assets/image/logo-digivox-x2.png";
import img5 from "./../assets/image/9.png";
import img6 from "./../assets/image/10.png";
import img7 from "./../assets/image/logo-squad-x2.png";

export default function Fold6() {
  return (
    <>
      <div id="partners"></div>

      <div className="row banner">
        <div className="col-12 d-flex align-items-center flex-column flex-lg-row">
          <div className="title">PARTNERS</div>
          <div className="line-small" />
          <div className="description plus text-center text-lg-start">
            Become a partner and exchange experiences with your best clients
          </div>
        </div>

        <div className="col-12 col-lg-6 col-xl-3">
          <div
            className="card-custom flex-column justify-content-center align-items-center"
            style={{ height: "370px" }}
          >
            <div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "140px" }}
              >
                <img src={img4} className="img-banner4" alt="ipng" />
              </div>
              <div className="description text-center mb-3">
                Telecommunications
              </div>
              <div className="description text-center mb-3">
                Unified Communication
              </div>
              <div className="description text-center mb-3">
                Omnichannel Communication
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6 col-xl-3">
          <div
            className="card-custom flex-column justify-content-center align-items-center"
            style={{ height: "370px" }}
          >
            <div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "140px" }}
              >
                <img src={img5} className="img-banner4" alt="ipng" />
              </div>
              <div className="description text-center mb-3">
                Customer Journey
              </div>
              <div className="description text-center mb-3">
                Customer service and collaboration between teams
              </div>
              <div className="description text-center mb-3">
                Sales management, leads and opportunities
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6 col-xl-3">
          <div
            className="card-custom flex-column justify-content-center align-items-center"
            style={{ height: "370px" }}
          >
            <div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "140px" }}
              >
                <img src={img6} className="img-banner4" alt="ipng" />
              </div>
              <div className="description text-center mb-3">Telemedicine</div>
              <div className="description text-center mb-3">
                Self-service appointment making
              </div>
              <div className="description text-center mb-3">
                Prescription and exam requisition
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6 col-xl-3">
          <div
            className="card-custom flex-column justify-content-center align-items-center"
            style={{ height: "370px" }}
          >
            <div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "140px" }}
              >
                <img src={img7} className="img-banner4" alt="ipng" />
              </div>
              <div className="description text-center mb-3">
                Collaboration between teams
              </div>
              <div className="description text-center mb-3">
                Audio and videoconference
              </div>
              <div className="description text-center mb-3">
                Desktop and mobile application
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
