import React from "react";
import CardCustomFold3 from "./CardCustomFold3";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import img4 from "./../assets/image/lemon_desk.png";
import img5 from "./../assets/image/lime_sales.png";
import img6 from "./../assets/image/tangy.png";
import img7 from "./../assets/image/juicy_analytics.png";

import blossomBot from "./../assets/image/logo-blossombot-x2.png";
import citricBrain from "./../assets/image/logo-citricbrain-x2.png";
import pameloFlow from "./../assets/image/logo-pomelo-x2.png";

const items = [
  <CardCustomFold3 img={img4}>
    <div className="description text-center mb-3">
      Help Desk and Call management solution
    </div>
    <div className="description text-center mb-3">
      Communication channels unified
    </div>
    <div className="description text-center mb-3">
      Customer Contact and service history
    </div>
    <div className="description text-center mb-3">Advanced SLA control</div>
    <div className="description text-center">Knowledge base</div>
  </CardCustomFold3>,

  <CardCustomFold3 img={img5}>
    <div className="description text-center mb-3">
      Sales Force and Insight Sales solution
    </div>
    <div className="description text-center mb-3">
      Lead, opportunity and customer management
    </div>
    <div className="description text-center mb-3">
      Issuance of digital proposals
    </div>
    <div className="description text-center mb-3">
      Digital signature for contracts and proposals
    </div>
    <div className="description text-center mb-3">Sales team management</div>
  </CardCustomFold3>,
  <CardCustomFold3 img={img6}>
    <div className="description text-center mb-3">
      Contact Center management solution
    </div>
    <div className="description text-center mb-3">Intelligent call routing</div>
    <div className="description text-center mb-3">
      Team productivity and performance analysis
    </div>
    <div className="description text-center mb-3">
      Monitoring and metrics dashboard
    </div>
    <div className="description text-center mb-3">Real-time reporting</div>
  </CardCustomFold3>,
  <CardCustomFold3 img={img7}>
    <div className="description text-center mb-3">
      Analytics e Business Intelligence solution
    </div>
    <div className="description text-center mb-3">
      Customer Journey data analysis
    </div>
    <div className="description text-center mb-3">
      Intelligent and personalized reporting
    </div>
  </CardCustomFold3>,
  <CardCustomFold3 img={blossomBot}>
    <div className="description text-center mb-3">Expert bots</div>
    <div className="description text-center mb-3">
      Automations in chats and messaging applications
    </div>
    <div className="description text-center mb-3">
      AI integration for sentiment analysis
    </div>
  </CardCustomFold3>,
  <CardCustomFold3 img={citricBrain}>
    <div className="description text-center mb-3">
      Artificial intelligence to automate processes
    </div>
    <div className="description text-center mb-3">
      Interpretation of patterns and behaviors
    </div>
    <div className="description text-center mb-3">
      Analysis of feelings of interactions
    </div>
  </CardCustomFold3>,
  <CardCustomFold3 img={pameloFlow}>
    <div className="description text-center mb-3">
      Creating flows and processes from system actions
    </div>
    <div className="description text-center mb-3">
      Harmonization of flows and processes
    </div>
    <div className="description text-center mb-3">
      Automation of flows from system actions
    </div>
  </CardCustomFold3>,
];

const responsive = {
  0: { items: 1 },
  959: { items: 2 },
  960: { items: 4 },
};

export default function Fold3() {
  return (
    <>
      <div id="our-products"></div>

      <div className="row banner">
        <div className="col-12 text-center">
          <div className="title">Start using our products now</div>
        </div>
        <AliceCarousel
          mouseTracking
          items={items}
          responsive={responsive}
          autoPlay={true}
          autoPlayInterval={4000}
          infinite={true}
          disableButtonsControls
          disableSlideInfo
        />
      </div>
    </>
  );
}
